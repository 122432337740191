import React from 'react';
import { Button, Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined  } from '@ant-design/icons';
import { useAuth } from '../../AuthContext'

const { Header } = Layout;

function MainHeader({ collapsed, toggleCollapsed, colorBgContainer }) {
  const { logout } = useAuth(); // Get the logout function from your authentication context

  const handleLogout = () => {
    logout(); // Call logout method from context to clear authentication state
  };

  return (
    <Header style={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', background: colorBgContainer }}>
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={toggleCollapsed}
        style={{ fontSize: '16px', width: 64, height: 64 , color: "white"}}
      />
      <Button
        type="text"
        icon={<LogoutOutlined />}
        onClick={handleLogout}
        style={{ fontSize: '16px', width: 64, height: 64, color: "white"}}
      />
    </Header>
  );
}

export default MainHeader;