import React, { useState } from 'react';
import { Layout, theme } from 'antd';


const { Content } = Layout;

function MoneyAdjustment() {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  return (
    <Content
        style={{
          padding: 24,
          minHeight: 1000,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
      <h1>ปรับยอดเงิน</h1>
        
    </Content>
  );
}

export default MoneyAdjustment;