import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import './App.css';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ReportDeposit from './pages/ReportDeposit';
import ReportWithdrawal from './pages/ReportWithdrawal';
import MainSidebar from './components/Sidebar/MainSidebar';
import MainHeader from './components/Header/MainHeader';
import ManageMember from './pages/ManageMember';
import MoneyAdjustment from './pages/MoneyAdjustment';
import Promotion from './pages/Promotion';
import InviteFriend from './pages/InviteFriend';
import ManageAdmin from './pages/ManageAdmin';
import { AuthProvider, useAuth } from './AuthContext';

const { Content } = Layout;

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

function AppRoutes() {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!isLoginRoute ? (
        <AppLayout />
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      )}
    </Layout>
  );
}

function AppLayout() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout>
      <MainSidebar collapsed={collapsed} />
      <Layout>
        <MainHeader collapsed={collapsed} toggleCollapsed={() => setCollapsed(!collapsed)} />
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <ProtectedRoutes />
        </Content>
      </Layout>
    </Layout>
  );
}

function ProtectedRoutes() {
  return (
    <Routes>
      <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
      <Route path="/member-management" element={<ProtectedRoute component={ManageMember} />} />
      <Route path="/report/deposit" element={<ProtectedRoute component={ReportDeposit} />} />
      <Route path="/report/withdrawal" element={<ProtectedRoute component={ReportWithdrawal} />} />
      <Route path="/adjustment" element={<ProtectedRoute component={MoneyAdjustment} />} />
      <Route path="/promotion" element={<ProtectedRoute component={Promotion} />} />
      <Route path="/invitefriend" element={<ProtectedRoute component={InviteFriend} />} />
      <Route path="/admin-management" element={<ProtectedRoute component={ManageAdmin} />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}

function ProtectedRoute({ component: Component }) {
  const { auth } = useAuth();
  return auth.isAuthenticated ? <Component /> : <Navigate to="/login" />;
}

export default App;
