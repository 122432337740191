import React, { useEffect } from 'react';
import {Button, Form, Input, Layout} from 'antd';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { useAuth } from '../AuthContext';

const { Content } = Layout;


function Login() {
    const { login, auth } = useAuth();  // Destructure the login function from the auth context
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated) {
          navigate('/dashboard');  // Redirect to dashboard if already logged in
        }
      }, [auth.isAuthenticated, navigate]);

    const handleLogin = async (values) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/admin/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            
            const data = await response.json();
            console.log(data);
            login(data.jwt, values.username);
            navigate('/dashboard');
        } catch (error) {
            console.error("Login failed:", error);
        }
    };


  return (
    <Layout>
        <Content className="login-page-container">
            <Form
                name="basic" labelCol={{span: 8,}} wrapperCol={{span: 16,}}
                className="login-page-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={handleLogin}
                autoComplete="off"
            >
                <img src="/asset_9.png" width="100px"></img>
                <h3>Please Login</h3>
                <Form.Item
                    className="login-form-item"
                    label="Username"
                    name="username"
                    rules={[
                        {
                        required: true,
                        message: 'Please input your username!',
                        },
                    ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                    className="login-form-item"
                    label="Password"
                    name="password"
                    rules={[
                        {
                        required: true,
                        message: 'Please input your password!',
                        },
                    ]}
                >
                <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    </Layout>
  );
}

export default Login;