import React, { useState } from 'react';
import { Layout, theme } from 'antd';


const { Content } = Layout;

function InviteFriend() {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  return (
    <Content
        style={{
          padding: 24,
          minHeight: 1000,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
      <h1>เชิญเพื่อน</h1>
        
    </Content>
  );
}

export default InviteFriend;