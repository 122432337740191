import React from 'react';
import { Menu, Layout } from 'antd';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import "./MainSidebar.css"
import { Link, useLocation } from 'react-router-dom';

const { Sider } = Layout;

function MainSidebar({collapsed}) {

  const location = useLocation();

  const getSelectedKey = (pathname) => {
    switch(pathname) {
      case '/dashboard':
        return '1';
      case '/member-management':
        return '2';
      case '/report/deposit':
        return '3';
      case '/report/withdrawal':
        return '4';
      case '/adjustment':
        return '5';
      case '/promotion':
        return '6';
      case '/invitefriend':
        return '7';
      case '/admin-management':
        return '8';
      default:
        return '1';  // Default to 'dashboard' if no path matches
    }
  };

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="sidebar-logo">
        <img src={collapsed ? "/logo60x42.png" : "/long_small_logo.png"}></img>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[getSelectedKey(location.pathname)]}
      >
        <Menu.Item key="1" icon={<UserOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<VideoCameraOutlined />}>
          <Link to="/member-management">จัดการสมาชิก</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<UploadOutlined />}>
          <Link to="/report/deposit">Report ฝากเงิน</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<UploadOutlined />}>
          <Link to="/report/withdrawal">Report ถอนเงิน</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<UploadOutlined />}>
          <Link to="/adjustment">ปรับยอดเงิน</Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<UploadOutlined />}>
          <Link to="/promotion">โปรโมชั่น</Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<UploadOutlined />}>
          <Link to="/invitefriend">แนะนำเพื่อน</Link>
        </Menu.Item>
        <Menu.Item key="8" icon={<UploadOutlined />}>
          <Link to="/admin-management">จัดการ Admin</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default MainSidebar;