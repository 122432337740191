import React, { createContext, useContext, useState } from 'react';

export const AuthContext = createContext({
    auth: { token: null, isAuthenticated: false, username: null },
    setAuth: () => {}
  });
  
  export function AuthProvider({ children }) {
    const [auth, setAuth] = useState({
      token: localStorage.getItem('winner-jwt'),
      isAuthenticated: !!localStorage.getItem('winner-jwt'),
      username: localStorage.getItem('username'),
    });

    // useEffect(() => {
    //   // Check local storage and update state accordingly upon app start/reload
    //   const jwt = localStorage.getItem('winner-jwt');
    //   const username = localStorage.getItem('username');
    //   if (jwt) {
    //     setAuth({
    //       token: jwt,
    //       isAuthenticated: true,
    //       username: username
    //     });
    //   }
    // }, []);

    const login = (token, username) => {
      localStorage.setItem('winner-jwt', token);  // Store the token in localStorage for persistent authentication
      localStorage.setItem('username', username);
      setAuth({ token: token, isAuthenticated: true, username: username });
    };
    

    const logout = () => {
        localStorage.removeItem('winner-jwt');
        localStorage.removeItem('username');
        setAuth({ token: null, isAuthenticated: false, username: null });
    };
  
    return (
      <AuthContext.Provider value={{ auth, setAuth, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  }
  
  export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        console.error('useAuth must be used within an AuthProvider');
        return;
    }

    // console.log('Auth State:', context.auth);
    return context;
  }