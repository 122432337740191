import React, { useState } from 'react';
import { Layout, theme, Card, Breadcrumb, Col, Row} from 'antd';
import MainSidebar from '../components/Sidebar/MainSidebar';
import MainHeader from '../components/Header/MainHeader';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'
import { UserOutlined, ArrowUpOutlined, ArrowDownOutlined, DollarCircleOutlined, NotificationOutlined, LikeOutlined } from '@ant-design/icons';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';


const { Content } = Layout;

const data = [
  { name: 'Mon', value: 50 },
  { name: 'Tue', value: 60 },
  { name: 'Wed', value: 80 },
  { name: 'Thu', value: 50 },
  { name: 'Fri', value: 60 },
  { name: 'Sat', value: 70 },
  { name: 'Sun', value: 150 }
];

function Dashboard() {
  
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
      <Content
        style={{
          padding: 24,
          minHeight: 1000,
          background: '#eeeeee',
          borderRadius: borderRadiusLG,
        }}
      >
        <div className="dashboard-main-container">
          <h1>Dashboard</h1>
          <div className="dashboard-container">
            <div className="dashboard-small-card-container" onClick={() => handleCardClick('/member-management')}>
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <UserOutlined style={{color: 'blue'}}/> New Member
                </p>

                <p className="dashboard-small-card-content">
                  100
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>

            <div className="dashboard-small-card-container" onClick={() => handleCardClick('/report/deposit')}>
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <ArrowUpOutlined style={{color: 'limegreen'}}/> Deposit
                </p>
                <p className="dashboard-small-card-content">
                  100
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>

            <div className="dashboard-small-card-container" onClick={() => handleCardClick('/report/withdrawal')}>
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <ArrowDownOutlined style={{color: 'red'}}/> Withdraw
                </p>
                <p className="dashboard-small-card-content">
                  100
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>

            <div className="dashboard-small-card-container" onClick={() => handleCardClick('/report/profit')}>
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <DollarCircleOutlined style={{color: 'green'}}/> Profit
                </p>
                <p className="dashboard-small-card-content">
                  100
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>

            <div className="dashboard-small-card-container">
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <UserOutlined style={{color: 'violet'}}/> Total Member
                </p>

                <p className="dashboard-small-card-content">
                  596
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>

            <div className="dashboard-small-card-container">
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <LikeOutlined style={{color: 'green'}}/> จำนวนครั้งที่ฝาก
                </p>
                <p className="dashboard-small-card-content">
                  100
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>

            <div className="dashboard-small-card-container">
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <LikeOutlined style={{color: 'green'}}/> จำนวนครั้งที่ถอน
                </p>
                <p className="dashboard-small-card-content">
                  50
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>

            <div className="dashboard-small-card-container">
              <div class="dashboard-small-card">
                <p className="dashboard-small-card-title">
                  <UserOutlined style={{color: 'blue'}}/> User Online
                </p>
                <p className="dashboard-small-card-content">
                  25
                </p>

                <div style={{ width: '100%'}}>
                  <AreaChart width={350} height={100} data={data} style={{ width: '100%'}}>
                    <defs>
                        <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide domain={['dataMin - 100', 'dataMax + 100']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorValue)" />
                  </AreaChart>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        
        
      </Content>
  );
}

export default Dashboard;