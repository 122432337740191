import { Layout, theme, Input} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../components/Table/DataTable';
import { useAuth } from '../AuthContext';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatUTC7 } from '../utils/dateUtils';


const { Content } = Layout;

function ReportWithdrawal() {

  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { auth} = useAuth();

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Amount', accessor: 'amount' },
    { Header: 'สถานะ', accessor: 'status' },
    { Header: 'วันที่ถอน ', accessor: 'createdAt',
      Cell: ({ value }) => formatUTC7(value) },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'id',
      Cell: ({ value }) => (
        <div>
          <EditOutlined onClick={() => handleEdit(value)} style={{ color: 'blue', marginRight: 12 }} />
          <DeleteOutlined onClick={() => handleDelete(value)} style={{ color: 'red' }} />
        </div>
      )
    }
  ], []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${apiUrl}/api/admin/withdrawal/all`, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            setData(result);
      } catch (error) {
          console.error('Error fetching members:', error);
      }
    };

    fetchData();
  }, [auth.token]);

  const filteredData = useMemo(() => {
    if (!searchInput) return data;
    return data.filter(
      item =>
        item.memberId.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        item.telephoneNumber.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.password.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [data, searchInput]);

  const handleEdit = (memberId) => {
    console.log("Editing member:", memberId);
    // Logic to handle editing a member
  };

  const handleDelete = (memberId) => {
      console.log("Deleting member:", memberId);
      // Logic to handle deletion of a member
  };

  return (
    <Content
        style={{
          padding: 24,
          minHeight: 1000,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
      <h1>Report ถอนเงิน</h1>
      
      <Input
        placeholder="Search Transactions"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        style={{ marginBottom: 20, width: 300 }}
      />

      <DataTable columns={columns} data={filteredData} />

    </Content>
  );
}

export default ReportWithdrawal;